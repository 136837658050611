<template>

    <div class="w-full h-full px-3 py-4">

        <div class="h-auto w-full rounded-lg shadow_box flex flex-col p-2 mb-6"  :class="'bg-module-'+mode">

            <All :model="model" :config="config" :immediate="true" v-slot="{data,loading}">
                
                <div v-if="!loading" class="h-auto">


                    <div v-if="!loading && user.rol.Name == 'Comercial'" class="h-auto px-2 mt-6 flex flex-col justify-center items-start">

                        <span class="text-md font-medium mb-2" :class="'text-dfont-'+mode" >{{$t('zone')}}</span>

                        <div class="h-8 w-full">
                            <div class="h-full w-full rounded-lg flex flex-row justify-between items-center px-2" :class="'bg-box-'+mode">

                                <select name="zoneFilter" v-model="zoneFilter" class="h-full w-full" :class="'bg-box-'+mode+' text-dfont-'+mode">

                                    <option v-for="(el,index) in comercial.relation" :key="index" :value="el.zonelink.IdZone+','+el.IdSaleType" :class="'text-dfont-'+mode">{{el.zonelink.zone.Name}} - {{el.saletype.Name}}</option>
                                    <option v-if="comercial.relation.length > 1" value="null">TODAS</option>

                                </select>

                            </div>
                        </div>

                    </div>

                    <div class="h-auto w-full px-2 mt-6 flex flex-col justify-center items-start">

                        <span class="text-md font-medium mb-2" :class="'text-dfont-'+mode" >{{$t('period')}}</span>

                        <div class="h-8 w-full">

                            <div class="h-full rounded-lg flex flex-row justify-between items-center px-2" :class="'bg-box-'+mode">

                                <select name="period" v-model="period" class="h-full w-full" :class="'bg-box-'+mode+' text-dfont-'+mode">
                                    <option v-for="(el,index) in periods" :key="index" :value="el.value" :class="'text-dfont-'+mode">{{el.name}}</option>
                                </select>

                            </div>

                        </div>

                    </div>

                </div>

                <div v-else class="h-20 relative">
                    <loader :loading="loading"></loader>
                </div>

            </All>

            <filter-months v-if="period == 'month'"   :mode='mode'/>
            
            <div class="h-10 mt-12 px-12">
                <div class="h-full rounded-lg bg-red flex flex-row justify-center items-center cursor-pointer" @click="applyFilter()">
                    <span class="text-lg text-white">{{$t('apply')}}</span>
                </div>
            </div>
            
            <div class="h-6"></div>

        </div>

    </div>

</template>

<script>
import { All } from '@/api/components';
import { state, actions } from '@/store';
import loader from '../../components/loader.vue';

export default {
    components:{
        All,
        loader
    },
    data(){
        return{
            zoneFilter: '',
            periods: [
                { 
                    name: this.$t("monthly"),
                    value: 'month'
                },
                { 
                    name: this.$t("quarterly"),
                    value: 'quarter'
                },
                { 
                    name: this.$t("annual"),
                    value: 'year'
                }
            ],
            period: '',
            saleType: '',
            saletypeFilter: ''
        }
    },
    methods:{
        
        applyFilter(){
            
            actions.setPeriod(this.period);

            if(state.user.rol.Name == 'Comercial'){

                if(this.zoneFilter){

                    actions.setIdZone(this.zoneFilter.split(',')[0]);
                    actions.setSaleTypeSelected(this.zoneFilter.split(',')[1]);
                    actions.setComercialSelected(null)


                    for (let index = 0; index < state.user.relation.length; index++) {

                        if(state.user.relation[index].zonelink.IdZone == this.zoneFilter.split(',')[0] && state.user.relation[index].IdSaleType == this.zoneFilter.split(',')[1]){

                            actions.setZoneSelected(state.relation[index].saletype.Name)
                            actions.setZone(state.relation[index].zonelink.zone)
                        }
                        
                    }

                } else{

                    actions.setComercialSelected(state.user.id)

                } 

            }

            this.$router.push({name:'zone'})
        }
    },
    computed:{
        comercial(){
            return state.comercialLogged 
        },
        model(){

            if(state.comercialSelected){

                return 'ComercialInfoUser'

            } else {

                return 'ComercialInfoZone'

            }

            

        },
        config(){

            if(state.comercialSelected){

                return{
                    data:{
                        Company: state.idCompany,
                        IdUser: state.comercialSelected
                    }
                }

            } else {

                if(state.user.rol.Name != 'Comercial'){

                    return{
                        data:{
                            Company: state.idCompany,
                            SaleType: state.saleTypeSelected,
                            Zone: state.zone.Id
                        }
                    }

                } else {

                    return{
                        data:{
                            Company: state.idCompany,
                            SaleType: state.saleTypeSelected,
                            // IdUser: state.user.id,
                            Zone: state.idZone
                        }
                    }

                }

            }
        },
        user(){
            return state.user
        },
        relations(){
            return state.relation
        },
        mode(){
            return state.mode
        }
    },
    mounted(){

        if(state.comercialSelected){
        
            this.zoneFilter = null

        } else {

            if(state.user.rol.Name != 'Comercial'){

                this.zoneFilter = state.filterZoneComercial.toUpperCase();

            } else {

                this.zoneFilter = state.idZone.toLowerCase()+','+state.saleTypeSelected.toLowerCase();

            }

        }
        
       this.period = state.period;
       this.saleType = state.saleTypeSelected;
       this.saletypeFilter = state.saleTypeSelected;
    }
}
</script>

<style>

    /* .el-input__inner{
        background-color: rgba(0,0,0,0) !important;
        border: 0;
        color: #2C5358;
    } */

</style>